.adyen-checkout__qr-loader {
  font-size: 13px;
  color: #26272a;
  padding: 1.5rem;
}
.adyen-checkout__qr-loader img {
  margin: auto;
  max-width: 70%;
}
.adyen-checkout__qr-loader__subtitle {
  text-align: center;
  font-size: 16px;
}
.adyen-checkout__qr-loader__payment_amount {
  font-size: 16px;
  margin-bottom: 20px;
}

.adyen-checkout__content-separator {
  padding: 10px 0;
  display: none;
}

.adyen-checkout__button {
  display: block;
  border-color: #7a7979;
  background: #4628f0;
  border-color: black;
  border-radius: 23px;
  color: #ffffff;
  cursor: pointer;
  font-size: 18px;
  line-height: 20px;
  height: 45px !important;
  font-weight: 500;
  width: 100%;
}

.adyen-checkout__qr-loader__subtitle.adyen-checkout__qr-loader__subtitle--result {
  color: #796f6f;
  font-size: 17px;
  font-weight: 700;
  margin-top: 20px;
}

@media (min-width: 976px) {
  /* Hides Adyen button only in desktop */
  .adyen-checkout__button {
    display: none;
  }
}
